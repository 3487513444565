import {
  Button,
  Card,
  Descriptions,
  Input,
  Space,
  Table,
  Form,
  Typography,
  Tabs,
  Modal,
  Select,
} from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { CommonPageHeader } from "../../components/CommonPageHeader";
import { STATUS } from "../../Constants";
import {
  getPackageRollsAction,
  getPkgStudentsAction,
  getSinglePackageAction,
  notifyAdmitCardAction,
  updateStudentPackageAction,
} from "../../redux/reducers/packages";
import _, { find, intersectionWith, pick } from "lodash";
import { bilingualText } from "../../utils/FileHelper";
import { EditOutlined, NotificationOutlined, SearchOutlined } from "@ant-design/icons";
import { ExportExcel } from "../../components/ExportExcel";
import { apis } from "../../services/api/apis";
import {
  ErrorMessage,
  FetchingMessage,
  SuccessMessage,
} from "../../Constants/CommonAlerts";
import { UpdateStudentPackageModal } from "./EditStuPackageModal";
import UploadNewStudents from "./uploadNewStudents";
import { ExamCompetitionSelector } from "../../components/ExamCompetitionSelector";

const Text = Typography.Text;

const { TabPane } = Tabs;

export const PackageStudents = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("1");

  const {
    getStudentsStatus,
    studentsList,
    getPkgStatus,
    allStudentRolls,
    currentPackage,
    getPackageRollsStatus,
  } = useSelector((state) => ({
    getStudentsStatus: state.packages.getPkgStudentsStatus,
    studentsList: state.packages.pkgStudents,
    getPkgStatus: state.packages.getSinglePackgStatus,
    currentPackage: state.packages.currentPackage,
    getPackageRollsStatus: state.packages.getPackageRollsStatus,
    allStudentRolls: state.packages.allStudentRolls,
  }));

  console.log('pkgStudents', studentsList);
  
  useEffect(() => {
    dispatch(getSinglePackageAction({ id: params.id, admin: true }));
    dispatch(getPackageRollsAction({ packageId: params.id }));
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(getPkgStudentsAction({ packageId: params.id, limit: 20000 }));
  }, [dispatch, params.id]);

  const changePage = (e) => {
    history.push(`/package-students/${params.id}/${e}`);
  };

  let allStudents = useMemo(
    () =>
      studentsList?.docs.length
        ? allStudentRolls?.length
          ? studentsList.docs.map((d) => ({
              ...d,
              rollNumber: _.find(
                allStudentRolls,
                (r) => r.user === d.user?._id
              ),
            }))
          : studentsList.docs
        : [],
    [allStudentRolls, studentsList]
  );

  let data = allStudents?.length ? _.filter(allStudents, (d) => d.user) : [];
  data = data.length
    ? _.sortBy(data, (d) => _.lowerCase(d.user.name)).map((stud) => {
        const pkg =
          stud.packages?.length &&
          _.find(stud.packages, (p) => p.package === params.id);
        return stud.user
          ? {
              rollNo: stud.rollNumber?.finalRoll,
              name: stud.user.name,
              father:stud.user?.fatherName,
              address:`${stud?.user?.address?.tehsil},${stud?.user?.address?.city},${stud?.user?.address?.state}`,
              contact: stud.user.contact,
              email: stud.user.email,
              dob: stud.user.dob
                ? moment(stud.user.dob).format("DD-MM-YYYY")
                : "",
              city: pkg?.city,
              subject: pkg?.subject,
              interestedCourse: pkg?.interestedCourse,
              center: stud.rollNumber?.center,
              updateCenter: "",

              //   packageStartDate:
              //     stud.packages?.length &&
              //     _.findIndex(stud.packages, (p) => p.package === params.id) !==
              //       -1
              //       ? moment(
              //           _.find(stud.packages, (p) => p.package === params.id)
              //             .assignedOn
              //         ).format("DD-MM-YYYY")
              //       : "",
              //   examCenter:
              //     stud.packages?.length &&
              //     _.findIndex(stud.packages, (p) => p.package === params.id) !==
              //       -1 &&
              //     _.find(stud.packages, (p) => p.package === params.id).center
              //       ? _.find(stud.packages, (p) => p.package === params.id).center
              //           .name
              //       : null,
              //   examCode:
              //     stud.packages?.length &&
              //     _.findIndex(stud.packages, (p) => p.package === params.id) !==
              //       -1 &&
              //     _.find(stud.packages, (p) => p.package === params.id).center
              //       ? _.find(stud.packages, (p) => p.package === params.id).center
              //           .code
              //       : null,
            }
          : {};
      })
    : [];

  const handleNotificationSend = () => {
    dispatch(notifyAdmitCardAction({ packageId: params.id }));
  };

  const handleGenerateRoll = async () => {
    FetchingMessage("please wait");
    const res = await apis.refreshPackageRollnos({ packageId: params.id });
    if (res.ok) {
      SuccessMessage("Success");
      dispatch(getSinglePackageAction({ id: params.id, admin: true }));
      dispatch(getPackageRollsAction({ packageId: params.id }));
    } else {
      ErrorMessage("Failed");
    }
  };

  const [editRoll, setEditRoll] = React.useState();
  const [visible, setVisible] = React.useState(false);

  const openEdit = (u) => {
    setEditRoll(u);
    setVisible(true);
  };
  const closeEdit = () => {
    setEditRoll();
    setVisible(false);
  };

  const _success = () => {
    closeEdit();
    SuccessMessage("Success");
    dispatch(getPkgStudentsAction({ packageId: params.id, limit: 20000 }));
    dispatch(getPackageRollsAction({ packageId: params.id }));
  };

  return (
    <div>
      <CommonPageHeader title="Students" />
      <br />
      <Card
        loading={
          getPkgStatus === STATUS.FETCHING ||
          getPackageRollsStatus === STATUS.FETCHING
        }
      >
        {getPkgStatus === STATUS.SUCCESS &&
        getPackageRollsStatus === STATUS.SUCCESS ? (
          <div>
            <Descriptions bordered column={1}>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bold" }}
                label="Package"
              >
                {bilingualText(currentPackage.name)}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bold" }}
                label="Total Students"
              >
                {studentsList?.total}
              </Descriptions.Item>
              {/* <Descriptions.Item labelStyle={{fontWeight:'bold'}} label="Current Students">{studentsList?.total}</Descriptions.Item> */}
              <Descriptions.Item
                labelStyle={{ fontWeight: "bold" }}
                label="Download Students Excel"
              >
                <ExportExcel
                  filename={bilingualText(currentPackage?.name)}
                  title="download"
                  data={data}
                />
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bold" }}
                label="Notify To Download Admit Card"
              >
                <Button
                  shape="round"
                  onClick={handleNotificationSend}
                  icon={<NotificationOutlined />}
                >
                  Send Notification / SMS to Offline students
                </Button>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bold" }}
                label="Generate Roll No."
              >
                <Button
                  shape="round"
                  onClick={handleGenerateRoll}
                  icon={<NotificationOutlined />}
                >
                  Generate Roll numbers only
                </Button>
              </Descriptions.Item>
            </Descriptions>
            <br />
            <Tabs onChange={setActiveTab} activeKey={activeTab}>
              <TabPane tab="Package Students" key={"1"}>
                <StudentTable
                  allStudents={allStudents}
                  packageId={params.id}
                  status={getStudentsStatus}
                />
              </TabPane>
              <TabPane tab="Upload new" key={"2"}>
                <UploadNewStudents
                  setActiveTab={setActiveTab}
                  packageId={params.id}
                  excelData={data}
                  previousStudents={allStudents.length}
                />
              </TabPane>
            </Tabs>
            <UpdateStudentPackageModal
              visible={visible}
              studentPackageRoll={editRoll}
              closeModal={closeEdit}
              currentPkg={currentPackage}
              onSuccess={_success}
            />
          </div>
        ) : null}
      </Card>
    </div>
  );
};

export const search = (title, searchInput) => ({
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },

  onFilter: (value, record) => {
    return record.user?.[title]
      ? record.user[title]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : "";
  },
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Form onFinish={() => confirm({ closeDropdown: true })}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            // htmlType="submit"
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            onClick={() => confirm({ closeDropdown: true })}
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </Form>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{ fontSize: "18px", color: filtered ? "#1890ff" : undefined }}
    />
  ),
});

export const StudentTable = ({ allStudents, status, packageId }) => {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    updateStudentPackageStatus
  } = useSelector((state) => (
  {
    updateStudentPackageStatus:state.packages.updateStudentPackageStatus,
  }));

  const [editModal, openEditModal] = useState(false)

  useEffect(() => {
    if(updateStudentPackageStatus === STATUS.SUCCESS){
      openEditModal(false)
    }
  }, [updateStudentPackageStatus,openEditModal])

  const handleNotificationSend = (user) => {
    dispatch(notifyAdmitCardAction({ packageId: params.id, user }));
  };

  const handleEdit = (stud) => {
    openEditModal(d => d ? false: stud)
  }

  return (
    <>
    {editModal && 
            <EditStudentDrawer studentData={editModal} closeDrawer={handleEdit}/>
            }
    <Table
      pagination={{ pageSize: 20, showSizeChanger: false }}
      dataSource={allStudents?.length ? _.filter(allStudents, (d) => d) : []}
      bordered
      loading={status === STATUS.FETCHING}
    >
      <Table.Column
        dataIndex=""
        title="Roll No."
        render={(d) => {
          return (
            <div>
              <div>{d?.rollNumber?.finalRoll || "-"}</div>
              {d?.rollNumber?.finalRoll ? (
                <div
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    // console.log("d", d);
                    handleNotificationSend(d.user?._id);
                  }}
                >
                  Send Message
                </div>
              ) : null}
            </div>
          );
        }}
      />
      <Table.Column
        dataIndex=""
        title="Student Name"
        {...search("name", inputRef)}
        sortDirections={["descend"]}
        defaultSortOrder="descend"
        sorter={(a, b, c) =>
          _.lowerCase(a.user?.name) < _.lowerCase(b.user?.name)
        }
        render={(d) => d.user?.name}
      />
      <Table.Column
        dataIndex=""
        title="Contact"
        render={(d) => d.user?.contact}
        {...search("contact", inputRef)}
      />
      <Table.Column dataIndex="" title="Email" render={(d) => d.user?.email} />
      <Table.Column
        dataIndex=""
        title="Date of Birth"
        render={(d) =>
          d.user?.dob ? moment(d.user?.dob).format("DD-MM-YYYY") : null
        }
      />
       <Table.Column
        dataIndex=""
        title="Course"
        render={(d) => {
          const pkg =
            d.packages?.length &&
            _.find(d.packages, (p) => p.package === packageId);
          return pkg?.interestedCourse;
        }}
      />
      <Table.Column
        dataIndex=""
        title="City"
        render={(d) => {
          const pkg =
            d.packages?.length &&
            _.find(d.packages, (p) => p.package === packageId);
          return pkg?.city;
        }}
      />

      <Table.Column
        dataIndex=""
        title="Subject"
        render={(d) => {
          const pkg =
            d.packages?.length &&
            _.find(d.packages, (p) => p.package === packageId);
          return pkg?.subject;
        }}
      />

      <Table.Column
        dataIndex=""
        title="Center"
        render={(d) => {
          return d.rollNumber?.center;
        }}
      />

      {/* <Table.Column
      title="Exam Center"
      dataIndex=""
      render={(d) => {
        let pkg =
          d?.packages?.length &&
          _.findIndex(d?.packages, (p) => p.package === params.id) !==
            -1 &&
          _.find(d.packages, (p) => p.package === params.id);
        let center = pkg.center;
        return (
          <div>
            {pkg && center ? (
              <Space direction="vertical">
                <Space>
                  <Text type="secondary">Center Name:</Text>{" "}
                  <Text>{center.name}</Text>
                </Space>
                <Space>
                  <Text type="secondary">Address:</Text>{" "}
                  <Text>{center.address}</Text>
                </Space>
                <Space>
                  <Text type="secondary">Code:</Text>{" "}
                  <Text>{center.code || "-"}</Text>
                </Space>
              </Space>
            ) : null}
            <Space>
              <Button onClick={() => openEdit(d)}>Edit</Button>
            </Space>
          </div>
        );
      }}
    /> */}

      <Table.Column
        dataIndex=""
        title="Assigned On"
        defaultSortOrder="descend"
        sorter={(a, b) =>
          _.findIndex(a.packages, (p) => p.package === packageId) != -1 &&
          _.findIndex(b.packages, (p) => p.package === packageId) != -1
            ? new Date(
                moment(
                  _.find(a.packages, (p) => p.package === packageId).assignedOn
                )
              ) -
              new Date(
                moment(
                  _.find(b.packages, (p) => p.package === packageId).assignedOn
                )
              )
            : false
        }
        key="assignedAt"
        render={(d) =>
          d.packages?.length &&
          find(d.packages, (p) => p.package === packageId)?.assignedOn
            ? moment(
                _.find(d.packages, (p) => p.package === packageId).assignedOn
              ).format("DD-MM-YYYY")
            : null
        }
      />
      <Table.Column
        dataIndex=""
        title="Center"
        render={(d) => 
          <div>
            <Button
                  shape="round"
                  onClick={() => handleEdit(d)}
                  icon={<EditOutlined />}
                >
                 Edit 
                </Button>
          </div>
        }
      />
    </Table>
    </>
  );
};

const EditStudentDrawer = ({closeDrawer, studentData}) => {
  const dispatch = useDispatch();

  const {
    currentPackage,
    configData,
  } = useSelector((state) => (
  {
    currentPackage: state.packages.currentPackage,
    configData: state.lmsConfig,
  }));

  const [data, setData] = useState({subject:'', city:'', interestedCourse:''})
  const [examModal , openExamModal] = useState(false)

  useEffect(() => {
    if(studentData){
      const defaultData = find(studentData.packages,pkg => pkg.package === currentPackage._id)

      if(defaultData){
        const data = pick(defaultData, ['subject', 'city', 'interestedCourse'])
        setData(data)
      }
    }
  }, [currentPackage, studentData])

  const select = (e, key) => {
    setData(d => ({...d, [key]:e.target  ? e.target.value : e}))
  }

  const _submit = () => {
    dispatch(updateStudentPackageAction({...data, packageId:currentPackage._id, studentId:studentData._id}))
  }

  const _selectExam = (exams) => {
    const exam = find(configData.defaultData.exams || [],ex => ex._id === exams[0])
    setData(d => ({...d, interestedCourse:exam.name.en}))
    openExamModal(false)
  }

  const exams  = useMemo(() => {
    return intersectionWith(configData.defaultData.exams, currentPackage.exams, (a,b) => a._id === b) || []
  }, [currentPackage, configData])
  
  
  return(
    <Modal visible={true} close onCancel={closeDrawer} title={'Edit (' +studentData.user.name+')'}
        footer={[
            <Button key="cancel" onClick={closeDrawer}>
                Cancel
            </Button>,
            <Button onClick={_submit} type="primary">
                Save
            </Button>,
        ]}
    >   
      <ExamCompetitionSelector
        modalStatus={examModal}
        closeModal={() => openExamModal(false)}
        competitionsData={configData.defaultData.competitions}
        examsData={configData.defaultData.exams}
        defaultExams={currentPackage.exams ? _.map(currentPackage.exams, (s) => s) : []}
        selectedExamsData={(exams) => _selectExam(exams)}
      />

      <form>
        <Form.Item label='Subject'>
          <Input value={data.subject} placeholder='subject' onChange={(e) => select(e, 'subject')}/>
        </Form.Item>
        <Form.Item label='City'>
          <Input value={data.city} placeholder='city' onChange={(e) => select(e, 'city')}/>
        </Form.Item>
        <Form.Item label='Course'>
          <Select value={data.interestedCourse} placeholder='Select Course' allowClear onChange={(e) => select(e, 'interestedCourse')}>
              {exams.map(exam => 
                <Select.Option key={exam._id} value={exam.name?.en}>{exam.name?.en}</Select.Option>
              )}
          </Select>
          {/* <HStack spacing={4}>
          {data.course ? <Text>{data.course}</Text> : ''}
          <Button onClick={() =>openExamModal(true)}>Select Course</Button>
          </HStack> */}
        </Form.Item>
      </form>         
    </Modal>
  )
}
