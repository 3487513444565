import { Card, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuthUser } from "../../App/Context";
import { CommonPageHeader } from "../../components/CommonPageHeader";
import { STATUS } from "../../Constants";
import {
  getSinglePackageAction,
  resetAddPackage,
  updatePackageAction,
} from "../../redux/reducers/packages";
import { PackageOffers } from "../ManagePackages/PackageOffers";
import { AssignPackageTests } from "./AssignPackageTests";
import { OfflinePackageDetails } from "./OfflinePackageDetails";
import { PackageTestsPricing } from "./PackageTestsPricing";

export const AddOfflinePackage = () => {
  const contentTypes = [
    { id: 1, type: "COURSE" },
    { id: 2, type: "BOOK" },
    { id: 3, type: "DRIVE" },
    { id: 4, type: "MAGAZINE" },
    { id: 6, type: "TEST" },
  ];
  const websiteContentTypes = [
    { id: 1, type: "audio" },
    { id: 2, type: "video" },
    { id: 3, type: "pdf" },
    { id: 4, type: "book" },
  ];
  const params = useParams();
  const auth = useAuthUser();
  const history = useHistory();
  const dispatch = useDispatch();

  const { currentPackage, packages, updatePackageStatus } = useSelector(
    (state) => ({
      currentPackage: state.packages.currentPackage,
      packages: state.packages,
      updatePackageStatus: state.packages.updatePackageStatus,
    })
  );

  const [updateData, setUpdateData] = useState();
  const [defaultSyllabus, setDefaultSyllabus] = useState({ exams: [] });

  useEffect(() => {
    return () => dispatch(resetAddPackage());
  }, [dispatch]);

  useEffect(() => {
    if (updatePackageStatus === STATUS.SUCCESS) {
      dispatch(getSinglePackageAction({ id: params.id, admin: true }));
    }
  }, [dispatch, params.id, updatePackageStatus]);

  useEffect(() => {
    if (packages.getSinglePackgStatus === STATUS.SUCCESS) {
      const currentPkg = packages.currentPackage;
      const data = {
        name: currentPkg.name,
        exams: currentPkg.exams,
        targetYear: currentPkg.targetYear,
        description: currentPkg.description,
        medium: currentPkg.medium,
        published: currentPkg.published,
        price: currentPkg.price,
        fakePrice: currentPkg.fakePrice,
        mode: currentPkg.mode,
        priority: currentPkg.priority,
        type: currentPkg.type,
        tags: currentPkg.tags,
        carousel: currentPkg.carousel,
        gst: currentPkg.gst,
        slug: currentPkg.slug,
        rating: currentPkg.rating,
        files: currentPkg.files,
        startDate: currentPkg.startDate,
        endDate: currentPkg.endDate,
        thumbnail: currentPkg.thumbnail,
        terms: currentPkg.terms,
        altName: currentPkg.altName,
        timetable: currentPkg.timetable,
        centers: currentPkg.centers || [],
        leadCaptureEnabled: currentPkg.leadCaptureEnabled,
        leadDisabledDays: currentPkg.leadDisabledDays,
        smsExamName: currentPkg.smsExamName,
        smsLink: currentPkg.smsLink,
        websiteContentType: currentPkg.websiteContentType,
        showAsRelevant: currentPkg.showAsRelevant,
        walletApplicable: currentPkg.walletApplicable,
        admitCardDate: currentPkg.admitCardDate,
        resultDate: currentPkg.resultDate,
        subjects: currentPkg.subjects,
        cities: currentPkg.cities,
        admitCardPublished: currentPkg.admitCardPublished,
        answerKeysPublished: currentPkg.answerKeysPublished,
        resultPublished: currentPkg.resultPublished,
      };
      setUpdateData(data);
    } else {
      setUpdateData(null);
    }
  }, [packages.currentPackage, packages.getSinglePackgStatus]);

  useEffect(() => {
    if (params.id)
      dispatch(getSinglePackageAction({ id: params.id, admin: true }));
  }, [dispatch, params.id]);

  const changeStep = (e) => {
    if (currentPackage)
      history.push(`/add-offline-test-package/${e}/${currentPackage._id}`);
  };

  return (
    <div>
      <CommonPageHeader title="Package Details" />
      <br />
      <Card>
        <Tabs
          type="card"
          centered
          activeKey={params.step}
          onChange={changeStep}
        >
          <Tabs.TabPane
            tab={
              <div
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  padding: "10px",
                  minWidth: "150px",
                }}
              >
                <b>Step 1</b>
                <br /> Package Details
              </div>
            }
            key="1"
          >
            <OfflinePackageDetails
              contentTypes={contentTypes}
              params={params}
              xxx
              updateData={updateData}
              auth={auth}
              defaultSyllabus={defaultSyllabus}
              websiteContentTypes={websiteContentTypes}
            />
          </Tabs.TabPane>
          {currentPackage?.name ? (
            <Tabs.TabPane
              tab={
                <div
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    padding: "10px",
                    minWidth: "150px",
                  }}
                >
                  <b>Step 2</b>
                  <br /> Assign Package
                </div>
              }
              key="2"
            >
              <AssignPackageTests
                contentTypes={contentTypes}
                currentPackage={currentPackage || {}}
              />
            </Tabs.TabPane>
          ) : null}

          {currentPackage?.name ? (
            <Tabs.TabPane
              tab={
                <div
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    padding: "10px",
                    minWidth: "150px",
                  }}
                >
                  <b>Step 3</b>
                  <br /> Add Offers
                </div>
              }
              key="3"
            >
              <PackageOffers currentPackage={currentPackage} />
            </Tabs.TabPane>
          ) : null}
          {/* {currentPackage?.name ?
                        <Tabs.TabPane tab={<div style={{ fontSize: '16px', textAlign: 'center', padding: '10px', minWidth: '150px' }}><b>Step 3</b><br /> Pricing</div>} key="3">
                            <PackageTestsPricing contentTypes={contentTypes} currentPackage={currentPackage || {}} />
                        </Tabs.TabPane>
                        : null
                    } */}
        </Tabs>
      </Card>
    </div>
  );
};
